import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import ContainerBox from "../../components/containerBox";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { CommonJumbotron } from "../../components/jumbotron";

const useStyles = makeStyles(theme => createStyles({
  content: {
    paddingTop: "160px",
    paddingBottom: "100px",
  },
  column: {
    [theme.breakpoints.down("md")]: {
      "&:first-child": {
        paddingBottom: 0,
      },
      "&:last-child": {
        paddingTop: 0,
      },
    },
  },
  divier: {
    margin: "40px 0",
  },
  officeMapImage: {
    width: "100%",
  },
  contactTitle: {
    display: "block",
    color: "#222222",
    fontSize: "20px",
    fontWeight: 700,
    marginBottom: "8px",
  },
  contactContent: {
    display: "block",
    color: "#858585",
    fontSize: "20px",
    textDecoration: "none",
    height: "86px",
  },
  contactPararaph: {
    [theme.breakpoints.down('sm')]:{
      marginBottom: 0,
    }
  }
}));
interface ContactPageProps {
  pageContext: {
    language: string;
  }
}
function ContactPage({ pageContext }: ContactPageProps){
  const classes = useStyles();
  const { language } = pageContext;
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    query {
      backgroundPlaceholder: file(
        relativePath: { eq: "contact/img-title-photo-contact@2x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mapPlaceholder: file(relativePath: { eq: "contact/img-map@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout language={language}>
      <SEO title="Contact" />
      <CommonJumbotron
        backgroundImageFluid={data.backgroundPlaceholder.childImageSharp.fluid}
        title={"Contact"}
      />

      <main className={classes.content}>
        <ContainerBox>
          <Img
            className={classes.officeMapImage}
            fluid={data.mapPlaceholder.childImageSharp.fluid}
            alt="office"
          />
          <Grid container spacing={8}>
            <Grid item xs={12} md={12} lg={6} className={classes.column}>
              <Divider className={classes.divier} />
              <p className={classes.contactPararaph}>
                <span className={classes.contactTitle}>Address</span>
                <span className={classes.contactContent}>
                  {intl.formatMessage({ id: "hpcnt_address" })}
                </span>
              </p>
              <p className={classes.contactPararaph}>
                <span className={classes.contactTitle}>Contact</span>
                <a
                  className={classes.contactContent}
                  href="mailto:contact@hpcnt.com"
                >
                  contact@hpcnt.com
                </a>
              </p>
              <p className={classes.contactPararaph}>
                <span className={classes.contactTitle}>Fax</span>
                <span className={classes.contactContent}>02-562-8880</span>
              </p>
            </Grid>
            <Grid item xs={12} md={12} lg={6} className={classes.column}>
              <Hidden mdDown>
                <Divider className={classes.divier} />
              </Hidden>
              <p className={classes.contactPararaph}>
                <span className={classes.contactTitle}>
                  {intl.formatMessage({ id: "business_partership" })}
                </span>
                <a
                  className={classes.contactContent}
                  target="_blank"
                  rel="noopener"
                  href="mailto:partnership@hpcnt.com"
                >
                  partnership@hpcnt.com
                </a>
              </p>
              <p className={classes.contactPararaph}>
                <span className={classes.contactTitle}>
                  {intl.formatMessage({ id: "tect_contact" })}
                </span>
                <a
                  className={classes.contactContent}
                  target="_blank"
                  rel="noopener"
                  href="mailto:tech-contact@hpcnt.com"
                >
                  tech-contact@hpcnt.com
                </a>
              </p>
              <p className={classes.contactPararaph}>
                <span className={classes.contactTitle}>
                  {intl.formatMessage({ id: "recruitment" })}
                </span>
                <a
                  className={classes.contactContent}
                  target="_blank"
                  rel="noopener"
                  href="mailto:career@hpcnt.com"
                >
                  career@hpcnt.com
                </a>
              </p>
            </Grid>
          </Grid>
        </ContainerBox>
      </main>
    </Layout>
  );
};

export default ContactPage;
